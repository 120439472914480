body.modal-open {
    padding: 0 !important;
}

.rn-popup-modal {
    .modal-content {
        // background-color: var(--background-color-2);
        border: 1 none;
        padding: 40px 20px;
        border-radius: 10px;
        // @media #{$sm-layout} {
        //     padding: 20px 15px;
        // }
    }
    .btn-close {
        margin: 0;
        position: fixed;
        z-index: 99;
        right: 30px;
        top: 30px;
        background: rgba(255, 255, 255, 0.08);
        opacity: 1;
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.3s;
        transition-delay: 0.3s;
        svg,
        i {
            color: #fff;
            width: 18px;
            font-size: 18px;
        }
    }
    .modal-header {
        border: 0 none;
        justify-content: center;
        padding: 0;
        // margin-bottom: 5px;
    }


    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 450px;
        }
    }

    &.show {
        .btn-close {
            opacity: 1;
        }
    }
}

.placebid-modal-wrapper {
    .title{
        // color: #fff;
        font-size: 22px;
        margin-top: 5px;
        svg {
            margin-left: 5px;
            margin-bottom: 3px;
        }
        svg:hover {
            cursor: pointer;
        }
    }

    p {
        // color: #fff;
        font-size: 16px;
    }
    .bid-content-top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-border);
        padding: 10px 0;
        span {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            color: var(--color-body);
        }
    }
    .bid-content-mid {
        padding: 20px 0;
        // display: flex;
        // justify-content: space-between;
        h6 {
            padding: 5px;
        }
        span {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            color: var(--color-body);
        }
        .bid-content-right {
            h5 {
                &:last-child {
                    color: var(--color-primary);
                }
            }
        }
    }
    .bit-continue-button {
        display: flex;
        justify-content: center;
    }
}